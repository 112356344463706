import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-load-bar',
  templateUrl: './load-bar.component.html',
  styleUrls: ['./load-bar.component.less']
})
export class LoadBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
