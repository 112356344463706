import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  @Output() toggleMenuEvent: EventEmitter<null> = new EventEmitter<null>();

  constructor() { }

  toggleMenu() {
    this.toggleMenuEvent.emit(null);
  }

  ngOnInit() {
  }

}
