import { Project } from 'src/app/_models/project';
import { Text } from 'src/app/_models/text';


export const PROJECTS: Project[] = [
  {
    id: 1,
    title: 'Furniture Design',
    description: 'A fresh take on an old friend.',
    featured: true,
    created: new Date('3/16/2010'),
    modified: new Date('9/1/2015'),
    thumb: '../../assets/project files/furniture design/furnitureDesignThumb.jpg',
    content: [
      Object.assign(new Text(), {
        id: 1,
        header: 'test',
        text: 'test',
        position: 1
      }) 
    ]
  },
  {
    id: 2,
    title: 'Drone',
    description: 'A fully rigged and textured character model.',
    featured: true,
    created: new Date('4/5/2010'),
    modified: null,
    thumb: '../../assets/project files/drone/droneThumb.jpg',
    content: []
  },
  {
    id: 3,
    title: 'Goliath',
    description: 'A 3D sculpted organic model based on the main character of the Disney cartoon Gargoyles.',
    featured: false,
    created: new Date('10/21/2009'),
    modified: new Date('5/10/2011'),
    thumb: '../../assets/project files/goliath/GoliathThumb.jpg',
    content: []
  },
  {
    id: 4,
    title: 'Petra',
    description: `A model based on the character Petra from the book "Ender's Game".`,
    featured: false,
    created: new Date('4/8/2010'),
    modified: new Date('2/18/2011'),
    thumb: '../../assets/project files/petra/PetraThumb.jpg',
    content: []
  },
  {
    id: 5,
    title: '3D Printed Dice Boxes',
    description: `Magnetic D&D dice boxes printed in a variety of materials. Design by Agrif (Thingiverse).`,
    featured: true,
    created: new Date('8/23/2018'),
    modified: null,
    thumb: '../../assets/project files/dice boxes/diceBoxesThumb.jpg',
    content: []
  },
  {
    id: 6,
    title: 'SD Card Holder',
    description: `An SD card holder in the shape of a spiral heart shell (Meiocardia Vulgaris).`,
    featured: false,
    created: new Date('3/28/2019'),
    modified: null,
    thumb: '../../assets/project files/heart shell/heartShellThumb.jpg',
    content: []
  },
  {
    id: 7,
    title: 'Soccer Ball Model',
    description: `A quick render of a soccer ball model that is part of another, larger project.`,
    featured: false,
    created: new Date('4/24/2016'),
    modified: null,
    thumb: '../../assets/project files/soccer ball/soccerBallThumb.jpg',
    content: []
  },
  {
    id: 8,
    title: 'Vintage Toy Box',
    description: `A polygon model based on a 1980s Little Tikes toybox (part of another, larger project).`,
    featured: false,
    created: new Date('4/28/2016'),
    modified: null,
    thumb: '../../assets/project files/toybox/toyBoxThumb.jpg',
    content: []
  },
  {
    id: 9,
    title: 'G. B. Piranesi',
    description: `A 3D render based on a sketch by 18th century Italian artist, Giovanni Battista Piranesi.`,
    featured: false,
    created: new Date('11/27/2002'),
    modified: null,
    thumb: '../../assets/project files/piranesi/piranesi.jpg',
    content: []
  },
  {
    id: 10,
    title: 'Junkyard Robot',
    description: `Concept art of robot that put itself together from pieces in a junkyard.`,
    featured: false,
    created: new Date('4/5/2005'),
    modified: null,
    thumb: '../../assets/project files/robot illustration/robotThumb.jpg',
    content: []
  },
  {
    id: 11,
    title: 'T-Shirt Design',
    description: `My entry for a T-Shirt design competition.`,
    featured: false,
    created: new Date('10/27/2011'),
    modified: null,
    thumb: '../../assets/project files/T&B T-shirts/shirtThumb.jpg',
    content: []
  },
  {
    id: 12,
    title: 'T&B Car',
    description: `A polygon car model.`,
    featured: true,
    created: new Date('3/20/2013'),
    modified: null,
    thumb: '../../assets/project files/T&B car/carThumb.jpg',
    content: []
  },
].sort((a: Project, b: Project): number => { return Number((b.modified || b.created)) - Number((a.modified || a.created)); });
